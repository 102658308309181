<template>
  <div class="fixed-bar">
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      class="hidden-md-and-up fixed--bar-mobile"
    >
      <v-list nav dense class="fixed--bar-mobile">
        <v-list-item-group v-model="group">
          <v-list-item v-for="(item, i) in getHeader" :key="i" :to="item.route">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar light max-height="70px" class="fixed-bar">
      <v-col lg="10" offset-lg="1" class="d-flex">
        <v-toolbar-title class="align-self-center"></v-toolbar-title>

        <a>
          <img
            class="logo"
            src="../../../public/platformaIcon.png"
            alt="Платформа"
            @click="goToMainPage"
          />
        </a>

        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-tabs color="black">
            <v-tab v-for="(item, i) in getHeader" :key="i" :to="item.route">
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </v-toolbar-items>
        <v-btn
          v-if="isAuthCheck()"
          color="white"
          depressed
          class="align-self-center text--secondary"
          @click="logout"
        >
          {{ $vuetify.lang.t("$vuetify.main.header.menu.exit") }}
          <v-icon color="primary">mdi-logout</v-icon></v-btn
        >
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </v-col>
    </v-toolbar>
  </div>
</template>

<script>
import router from "../../router";
import Vue from "vue";

export default {
  name: "headerComponent",
  data() {
    return {
      linkToMainPage: process.env.VUE_APP_MAIN_PAGE,
      headerTitle: "Платформа",
      drawer: false,
      group: null,
      logoImg: "../../../../public/logo.png",
    };
  },
  methods: {
    goToMainPage() {
      location.href = this.linkToMainPage;
    },
    isAuthCheck() {
      return this.$store.getters["user/isAuth"];
    },
    logout() {
      this.$store.commit("app/CHANGE_LOCALE", "uk");
      this.$store.commit("user/SET_LOGOUT");
      router.push("/auth");
      Vue.swal({
        target: document.getElementById("main"),
        text: this.$vuetify.lang.t("$vuetify.view.auth.logout"),
        icon: "success",
        timer: 3500,
        toast: true,
        position: "top-right",
        showConfirmButton: false,
      });
    },
  },
  computed: {
    getUserRolePatientOrFalse: function () {
      return this.$store.getters["user/getUser"].role === "psychologist";
    },
    getHeader: function () {
      const userRole = this.$store.getters["user/getUser"].role;
      switch (userRole) {
        case "patient":
          return this.patientMenu;
        case "admin":
          return this.adminMenu;
        case "psychologist":
          return this.psychologistMenu;
        case "redactor":
          return this.redactorMenu;
        default:
          return {};
      }
    },
    redactorMenu() {
      return [
        // {
        //   title: this.$vuetify.lang.t("$vuetify.main.header.menu.main"),
        //   route: "/dashboard/main",
        // },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.blog"),
          route: "/dashboard/blog",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.categories"),
          route: "/dashboard/categories",
        },
      ];
    },
    adminMenu() {
      return [
        // {
        //   title: this.$vuetify.lang.t("$vuetify.main.header.menu.main"),
        //   route: "/dashboard/main",
        // },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.blog"),
          route: "/dashboard/blog",
        },
        {
          title: "Редактора",
          route: "/dashboard/redactors",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.categories"),
          route: "/dashboard/categories",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.header.menu.psychologists"
          ),
          route: "/dashboard/psychologists",
        },
      ];
    },
    patientMenu() {
      return [
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.main"),
          route: "/dashboard/main",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.blog"),
          route: "/dashboard/blog",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.settings"),
          route: "/dashboard/settings",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.header.menu.ourPsychologists"
          ),
          route: "/dashboard/psychologist",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.payment"),
          route: "/dashboard/payment",
        },
      ];
    },
    psychologistMenu() {
      return [
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.main"),
          route: "/dashboard/main",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.blog"),
          route: "/dashboard/blog",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.settings"),
          route: "/dashboard/settings",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.header.menu.specialization"
          ),
          route: "/dashboard/specialization",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.main.header.menu.schedule"),
          route: "/dashboard/schedule",
        },
      ];
    },
  },
};
</script>

<style lang="sass" scoped>
.logo
  margin-top: 10px
  height: 30px

.fixed-bar
  position: sticky
  position: -webkit-sticky
  top: 0
  z-index: 5

.fixed--bar-mobile
  position: fixed !important
  z-index: 6
</style>
